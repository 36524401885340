import React from 'react';
import { useInView } from 'react-intersection-observer';

import { motion, useReducedMotion } from '../../utils/motion';

enum LogoAnimationState {
  Hide = 'hide',
  Show = 'show',
  Skip = 'skip',
}

export type AnimatedLogoProps = {
  className?: string;
  animateIn?: boolean;
  label?: string;
};

const pathVariants = {
  [LogoAnimationState.Hide]: {
    pathLength: 0,
    pathOffset: 0.5,
    opacity: 0,
  },
  [LogoAnimationState.Show]: {
    pathLength: 1,
    pathOffset: 0,
    // Null is a valid valid default value, that says use the current value - But the paths interpret it wrong, so force it as a number
    opacity: [(null as unknown) as number, 1],
    transition: {
      duration: 0.75,
      ease: 'easeOut',
      times: [0, 1],
    },
  },
  [LogoAnimationState.Skip]: {
    pathLength: 1,
    pathOffset: 0,
    opacity: 1,
    transition: {
      duration: 0,
    },
  },
};

/**
 * Renders an animated version of the Logo as an svg element.
 * It will be default fill the container it's rendered into, so be sure to set the height/width of the container element - or by adding a class to the logo itself.
 */
function Logo({ className, label, animateIn }: AnimatedLogoProps) {
  const shouldReduceMotion = useReducedMotion();
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
    skip: !animateIn,
  });

  return (
    <motion.svg
      aria-label={label}
      ref={ref}
      sx={{ height: 'auto' }}
      className={className}
      focusable={false}
      width="71.2"
      height="70.4"
      viewBox="0 0 71.2 70.4"
      initial={
        !animateIn || shouldReduceMotion
          ? LogoAnimationState.Show
          : LogoAnimationState.Hide
      }
      animate={
        inView
          ? shouldReduceMotion
            ? LogoAnimationState.Skip
            : LogoAnimationState.Show
          : undefined
      }
      variants={{
        [LogoAnimationState.Show]: {
          transition: {
            delayChildren: 0.2,
          },
        },
      }}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      >
        <motion.path
          variants={pathVariants}
          d="M3.1 43.3a32.87 32.87 0 012.6-23.1"
        />
        <motion.path
          variants={pathVariants}
          d="M8.4 15.7C14.4 7.4 24.2 2 35.3 2c5.1 0 10 1.2 14.4 3.3"
        />
        <motion.path
          variants={pathVariants}
          d="M54.6 8.1c8.4 6 14.1 15.1 14.5 28.2"
        />
        <motion.path
          variants={pathVariants}
          d="M38.9 6.5c-1.2-.1-2.4-.2-3.6-.2-16 0-28.9 12.9-28.9 28.9 0 1.2-.1 1.3.2 5.2.2 2.1.6 2.8.4 4.5-.2 1.7-2.2 3.3-2.2 3.3"
        />
        <motion.path
          variants={pathVariants}
          d="M64.3 52.2c3.2-21-1-37.8-20.1-44.5"
        />
        <motion.path
          variants={pathVariants}
          d="M11 31.2c1.9-11.7 12.1-20.5 24.4-20.7 13.4-.1 23.8 9.8 25 22.7"
        />
        <motion.path
          variants={pathVariants}
          d="M10.7 36.3s.5 7.5.1 9.5c-.5 3-4.1 6.5-4.1 6.5"
        />
        <motion.path
          variants={pathVariants}
          d="M60.8 38.2s.5 4.2-.3 11.3c-.7 5.9-1.4 8.7-1.4 8.7"
        />
        <motion.path
          variants={pathVariants}
          d="M19.5 45.2l-.4-10.1C18.9 26.2 26.4 19 35.3 19c9.4 0 15.7 7.3 16.8 16.2 1 7.7-.1 16.3-.1 16.3"
        />
        <motion.path
          variants={pathVariants}
          d="M26.7 16.6c2.6-1.2 5.5-1.9 8.6-1.9 4.9 0 9.5 1.7 13 4.7"
        />
        <motion.path
          variants={pathVariants}
          d="M22.6 19.2c-4.7 3.7-7.9 9.5-7.7 16 .1 5.4.9 10.6-.8 14-1.6 3.3-5 6.5-5 6.5"
        />
        <motion.path
          variants={pathVariants}
          d="M51.8 23.2s4.9 4.3 4.9 17.9-3.5 22.2-3.5 22.2"
        />
        <motion.path
          variants={pathVariants}
          d="M41.2 24.6c3.7 2.1 6.2 5.7 6.9 10.4 1 6.6.6 12.2-1.3 20.4-1.4 5.8-5 12.5-5 12.5"
        />
        <motion.path
          variants={pathVariants}
          d="M27.4 33.4c.8-3.6 4-6.3 7.9-6.3 4.5 0 8.1 3.7 8.5 8.1.4 3.8.4 7.8.4 7.8"
        />
        <motion.path
          variants={pathVariants}
          d="M31.7 68.3c5-7.5 6.5-12.1 7.8-18.3.9-4.2.5-8.6-.2-14.8-.3-2.1-1.9-3.8-4-3.8s-3.9 1.7-3.8 3.8c.8 9.3-.3 14.1-.3 14.1"
        />
        <motion.path variants={pathVariants} d="M18.3 50.2s-1.8 4.9-6.3 8.6" />
        <motion.path
          variants={pathVariants}
          d="M36.5 23.1c-.4 0-.8-.1-1.2-.1-6.7 0-12.4 5.4-12.1 12.1.2 5.5.8 10.8-.5 14.6-1.3 3.8-4.5 9-7.5 11.9"
        />
        <motion.path
          variants={pathVariants}
          d="M27.5 38.4s1 7.5-1.1 12.9c-1.8 4.8-3.7 8.4-7.8 12.7"
        />
        <motion.path
          variants={pathVariants}
          d="M35.3 35.2s1.2 10-.1 15.7c-1.7 7.5-4.1 11.6-8.4 16.6"
        />
        <motion.path
          variants={pathVariants}
          d="M44 47.7c-1 7.2-3.7 14.6-7.5 20.7"
        />
        <motion.path
          variants={pathVariants}
          d="M29.9 53.8c-1.9 5.1-4 8.3-7.3 12.2"
        />
        <motion.path
          variants={pathVariants}
          d="M50.8 56.5c-.5 2.1-1.9 6.6-3.6 9.8"
        />
      </g>
    </motion.svg>
  );
}

Logo.defaultProps = {
  label: 'Vælgererklæringers Logo',
};

export default Logo;
