import React from 'react';

import { Transition, motion } from '../../utils/motion';

export type ArrowProps = {
  className?: string;
};

const transition: Transition = {
  duration: 0.3,
};

function Arrow(props: ArrowProps) {
  return (
    <motion.svg
      width="17"
      height="12"
      aria-hidden
      focusable={false}
      initial={{ x: 0 }}
      variants={{
        hover: {
          x: 4,
        },
      }}
      transition={transition}
      {...props}
    >
      <g stroke="currentColor" strokeWidth="2" fill="none">
        <motion.path d="M10 1l5 5-5 5" />
        <motion.path
          d="M0 6h14"
          strokeDasharray="0 1"
          initial={{
            pathLength: 1,
            // @ts-ignore
            pathOffset: 1,
          }}
          variants={{
            hover: {
              // @ts-ignore
              pathOffset: 0,
            },
          }}
          transition={transition}
        />
      </g>
    </motion.svg>
  );
}

export default Arrow;
