import React, { useEffect, useState } from 'react';
import { Text } from '@charlietango/ui';
import useId from '@charlietango/use-id';
import useInteraction from '@charlietango/use-interaction';
import { rem } from 'polished';
import {
  Accordion as ReactAccordion,
  AccordionItem as ReactAccordionItem,
  AccordionItemButton as ReactAccordionItemButton,
  AccordionItemHeading as ReactAccordionItemHeading,
  AccordionItemPanel as ReactAccordionItemPanel,
} from 'react-accessible-accordion';

import { Colors } from '../../styles/colors';
import { FontWeight, TextVariant } from '../../styles/typography';
import {
  motion,
  useReducedMotion,
  useSpring,
  useTransform,
} from '../../utils/motion';
import TextBlockViewModel from '../../view-models/TextBlockViewModel';
import Indicator from '../Indicator/Indicator';
import RichText from '../RichText/RichText';

export enum AccordionOrderTypes {
  number = 'number',
  bullet = 'bullet',
  none = 'none',
}

export type AccordionProps = {
  items?: TextBlockViewModel[];
  orderType?: AccordionOrderTypes;
  indicator?: boolean;
  boldHeader?: boolean;
  background?: string | null;
  interpolationParams?: Record<string, React.ReactNode>;
};

type AccordionItemProps = {
  accUuid: string[];
  item: TextBlockViewModel;
  orderType: AccordionOrderTypes;
  index: number;
  indicator: boolean;
  boldHeader?: boolean;
  interpolationParams?: Record<string, React.ReactNode>;
};

function AccordionArrow({ isOpen }: { isOpen: boolean }) {
  const yOffset = useSpring(0, { damping: 25, stiffness: 200 });

  // Generate the SVG path we need to draw the arrow. It's transformed whenever the scale value updates,
  // causing it to move the 3 points in the arrow on the Y axis.
  const d = useTransform(
    yOffset,
    (value) => `M0 ${value} L6 ${5 - value} L12 ${value}`,
  );

  useEffect(() => {
    if (isOpen) yOffset.set(5);
    else yOffset.set(0);
  }, [isOpen, yOffset]);

  return (
    <div
      aria-hidden="true"
      css={{
        width: rem(12),
        lineHeight: 1.6,
      }}
    >
      <motion.svg focusable={false} width="14" height="7" viewBox="-1 -1 14 7">
        <motion.path d={d} stroke="currentColor" strokeWidth="2" fill="none" />
      </motion.svg>
    </div>
  );
}

function AccordionItem({
  accUuid,
  item,
  orderType,
  index,
  indicator,
  boldHeader,
  interpolationParams,
}: AccordionItemProps) {
  const shouldReduceMotion = useReducedMotion();
  const [ref, { hover, focusVisible }] = useInteraction();
  const accordionId = useId('accordion');
  const isOpen = !!(accordionId && accUuid.includes(accordionId));

  return (
    <div ref={ref} sx={{ position: 'relative' }}>
      {indicator && <Indicator active={isOpen || focusVisible || hover} />}
      <ReactAccordionItem
        sx={{
          boxShadow: '0 1px 0 0 #eee, inset 0 1px 0 0 #eee',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
        uuid={accordionId}
      >
        <div sx={{ width: '100%' }}>
          <ReactAccordionItemHeading>
            <ReactAccordionItemButton
              sx={{
                py: boldHeader ? 7 : 4,
                pl:
                  orderType === AccordionOrderTypes.none && !indicator ? 0 : 6,
                pr: 5,
                userSelect: 'none',
                flexGrow: 1,
                display: 'flex',
              }}
            >
              {orderType && orderType !== AccordionOrderTypes.none && (
                <Text
                  variant={TextVariant.Paragraph}
                  as="span"
                  sx={{
                    width: rem(20),
                    mr: 4,
                    position: 'absolute',
                  }}
                >
                  {orderType === AccordionOrderTypes.number && `${index + 1}.`}
                  {orderType === AccordionOrderTypes.bullet &&
                    String.fromCharCode(8226)}
                </Text>
              )}
              <Text
                variant={TextVariant.Paragraph}
                as="span"
                sx={{
                  pl: orderType === AccordionOrderTypes.none ? 0 : 5,
                  flexGrow: 2,
                  maxWidth: '100%',
                  fontWeight: boldHeader ? FontWeight.Medium : undefined,
                }}
              >
                {item.title}
              </Text>
              <AccordionArrow isOpen={isOpen} />
            </ReactAccordionItemButton>
          </ReactAccordionItemHeading>
          <ReactAccordionItemPanel
            sx={{
              pl: orderType === AccordionOrderTypes.none ? 0 : 5,
              pr: 5,
              display: 'block',
            }}
          >
            <motion.div
              initial={{ height: 0, opacity: 0, y: -20, display: 'none' }}
              css={{ overflow: 'hidden' }}
              animate={{
                height: isOpen ? 'auto' : '0px',
                opacity: isOpen ? 1 : 0,
                y: isOpen ? 0 : -20,
                display: 'block',
                transitionEnd: {
                  display: isOpen ? 'block' : 'none',
                },
              }}
              transition={
                shouldReduceMotion
                  ? { duration: 0 }
                  : { damping: 25, stiffness: 250 }
              }
            >
              <RichText
                sx={{
                  pt: boldHeader ? 0 : 2,
                  pb: 4,
                  ml: orderType === AccordionOrderTypes.none ? 0 : 6,
                  mr: 3,
                  maxWidth:
                    orderType === AccordionOrderTypes.none
                      ? rem(490)
                      : rem(470),
                }}
                headingsOffset={2}
                variant={TextVariant.Paragraph}
                params={interpolationParams}
              >
                {item.text}
              </RichText>
            </motion.div>
          </ReactAccordionItemPanel>
        </div>
      </ReactAccordionItem>
    </div>
  );
}

function Accordion({
  items,
  orderType = AccordionOrderTypes.number,
  indicator = true,
  boldHeader = false,
  background = Colors.PureWhite,
  interpolationParams,
}: AccordionProps) {
  const [accUuid, setAccUuid] = useState<Array<string>>([]);
  const callbackAcc = (varr: string[]) => {
    setAccUuid(varr);
  };
  return items && items.length > 0 ? (
    <ReactAccordion
      sx={{ backgroundColor: background ? Colors.PureWhite : 'none' }}
      allowZeroExpanded
      allowMultipleExpanded
      onChange={callbackAcc}
    >
      {items.map((item, index) => (
        <AccordionItem
          accUuid={accUuid}
          item={item}
          orderType={orderType}
          index={index}
          key={index}
          indicator={indicator}
          boldHeader={boldHeader}
          interpolationParams={interpolationParams}
        />
      ))}
    </ReactAccordion>
  ) : null;
}

export default Accordion;
