import React from 'react';
import { Button as BaseButton } from '@charlietango/ui';

import { ButtonVariant } from '../../styles/buttons';
import { motion } from '../../utils/motion';
import LinkViewModel from '../../view-models/LinkViewModel';
import { MotionAnchor } from '../Anchor/Anchor';
import Spinner from '../Spinner/Spinner';

export type ButtonProps = {
  onClick?: (event) => void;
  variant?: ButtonVariant;
  label?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  /** Set submitting to true to show a spinner, and disable the button */
  submitting?: boolean;
  disabled?: boolean;
  className?: string;
  type?: 'submit' | 'button';
  prefetch?: boolean;
  replaceHistory?: boolean;
  /** This Anchor is always going to be an External page */
  external?: boolean;
} & LinkViewModel;

function ButtonIcon({ icon, disabled, submitting }) {
  if (!icon) return null;
  return (
    <span
      aria-hidden="true"
      sx={{
        position: 'absolute',
        right: '18px',
        top: '50%',
        transform: 'translate(0, -50%)',
        visibility: disabled || submitting ? 'hidden' : undefined,
      }}
    >
      {icon}
    </span>
  );
}

function Button({
  variant,
  href,
  label,
  disabled,
  children,
  icon,
  submitting,
  type,
  prefetch,
  replaceHistory,
  title,
  ...rest
}: ButtonProps) {
  if (disabled && href) href = undefined;
  if (!label && !children) return null;

  const stringLabel = typeof children === 'string' ? children : label;

  if (href) {
    return (
      <BaseButton
        whileHover="hover"
        as={MotionAnchor}
        href={href}
        prefetch={prefetch}
        replaceHistory={replaceHistory}
        variant={variant}
        sx={{
          position: 'relative',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
          px: icon ? 7 : 5,
        }}
        title={title !== stringLabel ? title : undefined}
        {...rest}
      >
        {label || children}
        <ButtonIcon icon={icon} disabled={disabled} submitting={submitting} />
      </BaseButton>
    );
  }

  return (
    <BaseButton
      as={motion.button}
      whileHover="hover"
      aria-busy={submitting}
      aria-label={submitting ? stringLabel : undefined}
      disabled={disabled || submitting}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        px: icon ? 7 : 5,
        '&:disabled': {
          cursor: 'default',
        },
      }}
      variant={variant}
      {...rest}
    >
      <span
        sx={{
          opacity: submitting ? 0 : undefined,
        }}
      >
        {label || children}
      </span>
      <ButtonIcon icon={icon} disabled={disabled} submitting={submitting} />
      {submitting ? <Spinner instant size="2em" /> : null}
    </BaseButton>
  );
}

export default Button;
