import React from 'react';
import { Heading } from '@charlietango/ui';

import { useAuth } from '../../api/AuthProvider';
import { TextVariant } from '../../styles/typography';
import AccordionViewModel from '../../view-models/AccordionViewModel';
import Accordion from '../Accordion/Accordion';

type Props = AccordionViewModel & {
  className?: string;
};

function HowToSection({ title, items, className }: Props) {
  const { loginUrl } = useAuth();

  return (
    <section className={className}>
      <Heading variant={TextVariant.H3} sx={{ mb: [6, 7, 6] }}>
        {title}
      </Heading>
      {items && (
        <Accordion
          items={items}
          interpolationParams={{
            loginUrl,
          }}
        />
      )}
    </section>
  );
}

export default HowToSection;
