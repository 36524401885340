import React from 'react';

import { Colors } from '../../styles/colors';
import { motion, useReducedMotion } from '../../utils/motion';

export type IndicatorProps = { active: boolean };

function Indicator({ active }: IndicatorProps) {
  const shouldReduceMotion = useReducedMotion();
  return (
    <div
      sx={{
        position: 'absolute',
        width: '2px',
        height: '100%',
        userSelect: 'none',
        backgroundColor: Colors.Primary,
      }}
    >
      <motion.div
        initial={{
          scaleY: 0,
        }}
        animate={{
          scaleY: active ? 1 : 0,
        }}
        transition={{ duration: shouldReduceMotion ? 0 : 0.2 }}
        sx={{
          width: '100%',
          height: '100%',
          transformOrigin: '100% 0%',
          backgroundColor: Colors.Black,
        }}
      />
    </div>
  );
}

export default Indicator;
